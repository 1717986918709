<template>
  <v-container class="container--fluid grid-list-md">
    <h2 class="mb-2">{{$t('route.xmls.import')}}</h2>
    <a href="https://pricecreator-rozetka.tatet.net/seller/import/xml/">Ссылка</a><br>
  </v-container>
</template>

<script>
export default {
name: "importXML"
}
</script>

<style scoped>

</style>
